import React from "react";
import { Navigate } from "react-router-dom";

function Protected({ visible, children }) {
  console.log("visible", visible);

  if (visible === false) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
}
export default Protected;
