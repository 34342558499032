import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../dashboard/scss/index.scss';
import { useGS50Contract } from '../../../../Hooks/';
import useCryptoPrice from '../../../../Hooks/useCryptoPrice';
import { useAddress, useChainId } from '@thirdweb-dev/react';
import { Header, Footer } from '../../../../layout';
import Web3 from 'web3';
import { ethers } from 'ethers';

// Network logos
import avaxLogo from '../../../../assets/images/networks/avax.png';
import baseLogo from '../../../../assets/images/networks/base.png';
import bnbLogo from '../../../../assets/images/networks/bnb.png';
import ethLogo from '../../../../assets/images/networks/eth.png';
import polygonLogo from '../../../../assets/images/networks/polygon.png';
import Logo from '../../../../assets/images/networks/8020.png';

const DashboardPage = ({ visible }) => {
  const account = useAddress();
  const chainId = useChainId();
  const { contract: contractInstance, isContractReady, symbol } = useGS50Contract(chainId);

  const { cryptoPrice } = useCryptoPrice(chainId);
  const [refAddress, setRefAddress] = useState("loading");
  const [buyAmount, setBuyAmount] = useState('');
  const navigate = useNavigate();
  const [sellAmount, setSellAmount] = useState('');
  const [userInfo, setUserInfo] = useState({
    gs50Held: 'No wallet connected.',
    rewardsEarned: 'Loading...',
    bonusEarned: 'Loading...',
    commissionEarned: 'Loading...',
    totalEarned: 'Loading...',
    totalSupply: 'Loading...'
  });


  useEffect(() => {
    const fetchUserInfo = async () => {
      // Log current state of variables for debugging
      // console.log("Fetching user info...");
      // console.log("Account:", account);
      // console.log("Chain ID:", chainId);
      // console.log("Contract Instance:", contractInstance);
      // console.log("Is Contract Ready:", isContractReady);
  
      // Check for account and chainId
      if (!account) {
        // console.log("No account found.");
        return;
      }
  
      if (!chainId) {
        // console.log("No chain ID found.");
        return;
      }
  
      if (!contractInstance || !isContractReady) {
        // console.log("Contract instance not ready or unavailable.");
        return;
      }

      try {
        // console.log("Attempting to fetch contract data...");
        // console.log("Symbol", symbol);

        const gs50HeldWei = await contractInstance.balanceOf(account);
        // console.log(`GS50 Held (Wei): ${gs50HeldWei}`);
        const rewardsEarnedWei = await contractInstance.dividendsOf(account);
        // console.log(`Rewards Held (Wei): ${rewardsEarnedWei}`);
        const bonusEarnedWei = await contractInstance.loyaltyOf();
        // console.log(`Bonus Held (Wei): ${bonusEarnedWei}`);
        const referralEarnedWei = await contractInstance.referralBalance_(account);
        // console.log(`Referral Made (Wei): ${referralEarnedWei}`);
        const totalSupplyWei = await contractInstance.totalSupply();

        const gs50HeldEther = ethers.utils.formatEther(gs50HeldWei);
        const rewardsEarnedEther = ethers.utils.formatEther(rewardsEarnedWei);
        const bonusEarnedEther = ethers.utils.formatEther(bonusEarnedWei);
        const referralEarnedEther = ethers.utils.formatEther(referralEarnedWei);
        const totalSupplyEther = ethers.utils.formatEther(totalSupplyWei);

        const formatNumber = (numberString) => {
          return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2
          }).format(parseFloat(numberString));
        };

                // Summing the values
                const totalEarnedEther = parseFloat(rewardsEarnedEther) + parseFloat(bonusEarnedEther) + parseFloat(referralEarnedEther);
  
                // Formatting the total to two decimal places
                const formattedTotalEarned = totalEarnedEther;
        
                const numericCryptoPrice = parseFloat(cryptoPrice); // Convert to number if necessary
        
          
                setUserInfo({
                  gs50Held: gs50HeldEther,
                  totalSupply: totalSupplyEther,
                  rewardsEarned: `$${(parseFloat(rewardsEarnedEther) * numericCryptoPrice).toFixed(4)}`,
                  bonusEarned: `$${(parseFloat(bonusEarnedEther) * numericCryptoPrice).toFixed(4)}`,
                  referralEarned: `$${(parseFloat(referralEarnedEther) * numericCryptoPrice).toFixed(4)}`,
                  totalEarned: `$${(parseFloat(formattedTotalEarned) * numericCryptoPrice).toFixed(4)}`,
                  rewardsEarnedEther: `${parseFloat(rewardsEarnedEther).toFixed(5)}`,
                  bonusEarnedEther: `${parseFloat(bonusEarnedEther).toFixed(5)}`,
                  referralEarnedEther: `${parseFloat(referralEarnedEther).toFixed(5)}`,
                  totalEarnedEther: `${parseFloat(totalEarnedEther).toFixed(5)}`,
          
        
                });
        
        
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };
    
    // Call fetchUserInfo when account, chainId, contractInstance, and isContractReady change
    fetchUserInfo();
  }, [account, chainId, contractInstance, isContractReady, cryptoPrice]);
   
  // const buyToken = async () => {
  //   if (contractInstance && buyAmount) {
  //     const amountInWei = ethers.utils.parseEther(buyAmount.toString());
  //     if (buyAmount === 0) {
  //       alert("ERROR: no amount");
  //       return;
  //     }
  
  //     // This assumes that the buy function in your smart contract requires
  //     // the referrer's address as a parameter and sends ether along with the call.
  //     // Adjust the parameters as needed based on your contract's buy function.
  //     try {
  //       // Setting up the transaction with the necessary value and gas limit.
  //       // You may need to adjust the gas limit based on the requirements of your contract's function.
  //       const transactionParameters = {
  //         value: amountInWei,
  //         // Optionally you can set gasLimit here if necessary
  //       };
  
  //       // Hardcoded fallback referral address
  //       const fallbackRefAddress = "0x1F7DcF5bf9CcD1fEA770cF078586E02DaE3bb8DA";
  //       const effectiveRefAddress = refAddress && refAddress !== "loading" && refAddress !== "0x0000000000000000000000000000000000000000" ? refAddress : fallbackRefAddress;
  
  //       // Performing the buy transaction
  //       const txResponse = await contractInstance.buy(effectiveRefAddress, transactionParameters);
  //       const txReceipt = await txResponse.wait(); // Wait for the transaction to be mined
  //       console.log("Transaction Completed!", txReceipt);
  //     } catch (error) {
  //       console.error('Error buying tokens:', error);
  //     }
  //   } else {
  //     alert("ERROR: no amount specified or contract not initialized");
  //   }
  // };

  const buyToken = async () => {
    // First, let's determine the chain ID
    let chainId;
    try {
      chainId = (await contractInstance.provider.getNetwork()).chainId;
    } catch (error) {
      console.error('Error fetching network:', error);
      alert("ERROR: Could not fetch network details");
      return;
    }
  
    // Check if the chainID is 56 (BSC) or 137 (Polygon)
    if (chainId === 56 || chainId === 137) {
      alert("ERROR: Buying GS50 is disabed for this network because of the migration to AVAX and BASE.  You can only sell and withdraw your assets from this chain.");
      return; // Exit the function early
    }
  
    if (contractInstance && buyAmount) {
      const amountInWei = ethers.utils.parseEther(buyAmount.toString());
      if (buyAmount === 0) {
        alert("ERROR: no amount");
        return;
      }
  
      try {
        const transactionParameters = {
          value: amountInWei,
          // Optionally you can set gasLimit here if necessary
        };
  
        const fallbackRefAddress = "0x1F7DcF5bf9CcD1fEA770cF078586E02DaE3bb8DA";
        const effectiveRefAddress = refAddress && refAddress !== "loading" && refAddress !== "0x0000000000000000000000000000000000000000" ? refAddress : fallbackRefAddress;
  
        const txResponse = await contractInstance.buy(effectiveRefAddress, transactionParameters);
        const txReceipt = await txResponse.wait(); // Wait for the transaction to be mined
        console.log("Transaction Completed!", txReceipt);
      } catch (error) {
        console.error('Error buying tokens:', error);
      }
    } else {
      alert("ERROR: no amount specified or contract not initialized");
    }
  };
  
  

  
  

  const sellToken = async () => {
    if (contractInstance && sellAmount) {
      const amountInTokens = ethers.utils.parseUnits(sellAmount.toString(), 'ether'); // Converting the amount to the appropriate unit
      if (sellAmount === 0) {
        alert("ERROR: no amount");
        return;
      }
  
      try {
        // The sell function call assumes that your contract's sell function
        // only needs the amount of tokens to sell as input.
        // Adjust the call as necessary based on your smart contract's requirements.
        const txResponse = await contractInstance.sell(amountInTokens);
        const txReceipt = await txResponse.wait(); // Wait for the transaction to be mined
        console.log("Sell Transaction Successful", txReceipt);
      } catch (error) {
        console.error('Error selling tokens:', error);
      }
    } else {
      alert("ERROR: no amount specified or contract not initialized");
    }
  };
  

  const claimRewards = async () => {
    if (contractInstance) {
      try {
        // Assuming 'withdraw' requires no parameters and simply needs to be called by the account
        const txResponse = await contractInstance.withdraw();
        const txReceipt = await txResponse.wait(); // Wait for the transaction to be mined
        console.log("Rewards successfully claimed", txReceipt);
      } catch (error) {
        console.error('Error claiming rewards:', error);
      }
    } else {
      alert("Contract not initialized");
    }
  };
  

  // const compoundRewards = async () => {
  //   if (contractInstance) {
  //     try {
  //       // Assuming 'reinvest' is the correct method name for compounding rewards and it requires no parameters.
  //       const txResponse = await contractInstance.reinvest();
  //       const txReceipt = await txResponse.wait(); // Wait for the transaction to be mined
  //       console.log("Rewards successfully compounded", txReceipt);
  //     } catch (error) {
  //       if (error.code === 4001) {
  //         // User rejected the transaction
  //         console.log("Transaction rejected by user");
  //       } else {
  //         console.error('Error compounding rewards:', error);
  //       }
  //     }
  //   } else {
  //     alert("Contract not initialized");
  //   }
  // };

  const compoundRewards = async () => {
    if (contractInstance) {
      // First, determine the chain ID
      let chainId;
      try {
        chainId = (await contractInstance.provider.getNetwork()).chainId;
      } catch (error) {
        console.error('Error fetching network:', error);
        alert("ERROR: Could not fetch network details");
        return;
      }
  
      // Check if the chainID is 56 (BSC) or 137 (Polygon)
      if (chainId === 56 || chainId === 137) {
        alert("ERROR: Compounding is disabed for this network because of the migration to AVAX and BASE. You can only sell and withdraw your assets from this chain.");
        return; // Exit the function early
      }
  
      try {
        const txResponse = await contractInstance.reinvest();
        const txReceipt = await txResponse.wait(); // Wait for the transaction to be mined
        console.log("Rewards successfully compounded", txReceipt);
      } catch (error) {
        if (error.code === 4001) {
          // User rejected the transaction
          console.log("Transaction rejected by user");
        } else {
          console.error('Error compounding rewards:', error);
        }
      }
    } else {
      alert("Contract not initialized");
    }
  };
  
  


const copyToClipboard = () => {
  // Create a new textarea element
  const el = document.createElement("textarea");

  // Optional: Style the textarea to be unobtrusive
  el.style.position = 'absolute';
  el.style.left = '-9999px';

  // Set the value to the referral link you wish to copy
  el.value = `https://unitedsuccess.center/dashboard?ref=${account}`;

  // Append the textarea to the document body
  document.body.appendChild(el);

  // Select the content of the textarea
  el.select();

  // Copy the selected content to the clipboard
  document.execCommand("copy");

  // Remove the textarea from the document body
  document.body.removeChild(el);

  // Optional: Notify the user that the text has been copied
  alert("Referral link copied to clipboard!");
};

  

const navigateBack = () => navigate('/'); // Simplified navigate back function

  // Function to get the network logo based on chainId
  const getNetworkLogo = (chainId) => {
    switch (chainId) {
      case 43114: return avaxLogo;
      case 8453: return baseLogo;
      case 56: return bnbLogo;
      case 97: return bnbLogo
      case 1: return ethLogo;
      case 137: return polygonLogo;
      default: return ''; // Default case if network is not supported
    }
  };


  return (
    <>
      <Header visible={visible}/>
      <div className="dashboard-page-content">
        <div className="info-display">
        <div className="info-item-container">
          <div className="info-item">
          <label style={{ fontWeight: 'bold' }}>GS50 Balance: </label><span>{userInfo.gs50Held}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>Circulating Supply: </label><span>{userInfo.totalSupply} </span>
          </div>

          <div className="info-item rewards-earned">
          <label style={{ fontWeight: 'bold' }}>Rewards Earned: </label><span>{userInfo.rewardsEarned}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>{symbol} </label><span>{userInfo.rewardsEarnedEther}</span>
          </div>

          <div className="info-item">
          <label style={{ fontWeight: 'bold' }}>Bonus Earned: </label><span>{userInfo.bonusEarned}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>{symbol} </label><span>{userInfo.bonusEarnedEther}</span>
          </div>

          <div className="info-item">
          <label style={{ fontWeight: 'bold' }}>Commission Earned: </label><span>{userInfo.referralEarned}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>{symbol} </label><span>{userInfo.referralEarnedEther}</span>
          </div>

          <div className="info-item">
          <label style={{ fontWeight: 'bold' }}>Total Earned: </label><span>{userInfo.totalEarned}</span>
          </div>
          <div className="info-item info-item-small">
          <label style={{ fontWeight: 'bold' }}>{symbol} </label><span>{userInfo.totalEarnedEther}</span>
          </div>
          </div>


          <div className="network-logos">
          <img style={{ filter: chainId === 43114 ? 'none' : 'grayscale(100%)' }}
          src={avaxLogo}
          alt="Avalanche"/>
          <img style={{ filter: chainId === 8453 ? 'none' : 'grayscale(100%)' }}
          src={baseLogo}
          alt="Base"/>
          <img style={{ filter: chainId === 56 ? 'none' : 'grayscale(100%)' }}
          src={bnbLogo}
          alt="Binance Smart Chain"/>
          <img style={{ filter: chainId === 97 ? 'none' : 'grayscale(100%)' }}
          src={bnbLogo}
          alt="Binance Smart Chain"/>
          <img style={{ filter: chainId === 1 ? 'none' : 'grayscale(100%)' }}
          src={ethLogo}
          alt="Ethereum"/>
          <img style={{ filter: chainId === 137 ? 'none' : 'grayscale(100%)' }}
          src={polygonLogo} alt="Polygon"/>
    
        </div>
        </div>
        
        <div className="trading-area">
        <div className="left-buttons">
          <div className="input-button-group">
            <input
              id="swapEth"
              // ref={swapEth}
              type="number"
              value={buyAmount}
              onChange={(e) => setBuyAmount(e.target.value)}
              placeholder={`Amount in ${symbol}`}
            />
            <button onClick={() => {
                buyToken();
                }}>Buy GS50</button>
            
          </div>

         
          <div className="divider"></div>

          <div className="input-button-group">
            <input
              type="number"
              value={sellAmount}
              onChange={(e) => setSellAmount(e.target.value)}
              placeholder="Amount in GS50"
            />
            <button onClick={sellToken}>Sell GS50</button>
            </div>
          </div>

          <div className="right-buttons">
          <div className="input-button-group">
            <button onClick={claimRewards}>Claim</button>
            <button onClick={compoundRewards}>Compound</button>
            <button onClick={copyToClipboard}>Copy Link</button>
            <a href="http://www.the8020.org" target="_blank" rel="noopener noreferrer">
  <img src={Logo} alt="Powered By" className="network-logo-image" />
</a>
          </div>
          </div>
        </div>
        <div className='component-footer'>
          <div className="close-icon" onClick={navigateBack}>
          <FontAwesomeIcon icon={faTimes} /> Close Dashboard
        </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DashboardPage;
