import React from "react";
import { About, MembersLanding } from "../index";
import DashboardOverlay from "../mainpage/memberslanding/dashboard/index.js";
import "./about/scss/index.scss";

export const MainPage = () => {
  return (
    <div className="mainDVG_Div">
      <About/>
      <MembersLanding/>
      {/* <DashboardOverlay/> */}
      {/* <MintProcess/> */}
      {/* <Projects/> */}
      {/* <ContactUs/> */}
    </div>
  );
};
