import React, { useState, useEffect } from "react";
import "./scss/index.scss";
import logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { Sidebar } from "../sidebar";
import { ConnectWallet, useAddress, useChainId } from "@thirdweb-dev/react";
import useCryptoPrice from '../../Hooks/useCryptoPrice';
import { useGS50Contract } from '../../Hooks/index';

export const Header = ({ visible }) => {
  const account = useAddress();
  const chainId = useChainId();
  const { contract, logo: contractLogo, symbol, gs50PriceInUsd } = useGS50Contract(chainId);
  const { cryptoPrice } = useCryptoPrice(chainId);
  const [isOpen, setIsopen] = useState(false);

  useEffect(() => {
    if (cryptoPrice !== "Loading...") {
      // Further logic to adjust GS50 price in USD if necessary
    }
  }, [cryptoPrice]);
  
  const ToggleSidebar = () => {
    setIsopen(!isOpen);
  };

  return (
    <div className="navbar_Section">
      <div className="nested_navbarSection">
        <div className="navbar_contentSection">
          <div className="logo">
            <NavLink to={"/"}>
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className="prices">
            <div className="price-item">
              {symbol} PRICE: <span style={{ color: "green" }}>{cryptoPrice}</span>
            </div>
            <div className="price-item">
              GS50 PRICE: <span>{gs50PriceInUsd}</span>
            </div>
          </div>
          <div className="links">
            <NavLink
              className={"px-3"}
              to={"https://www.facebook.com/al.murray.507"}
              target="_blank"
            >
              Facebook
            </NavLink>
            {/* <NavLink
              className={"px-3"}
              to={"https://www.instagram.com/jamarjames/"}
              target="_blank"
            >
              Instagram
            </NavLink> */}
            {/* <NavLink className={"px-3"} to={"/dcg-metaverse"} target="_blank">
              DCG Metaverse
            </NavLink> */}
            {/* <NavLink className={"px-3"} to={"/dcg-promotions"}>
              Discord Access
            </NavLink> */}
            {visible ? (
              <div className="dropdown">
                <NavLink to= "/portal" className="dropdown-toggle">Members Portal</NavLink>
                {/* <div className="dropdown-content">
                  <>
                    <NavLink to="/dashboard">Dashboard</NavLink>
                    <NavLink to="/media">Media Center</NavLink>
                    <NavLink to="/opportunity">Opportunities</NavLink>
                    <NavLink to="/store">Store</NavLink>
                    <NavLink to="/training">Training</NavLink>
                  </>
                </div> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          <ConnectWallet />
          



          
        </div>
      </div>
      <Sidebar ToggleSidebar={ToggleSidebar} isOpen={isOpen} visible={visible} />
      {/* <TradingViewWidget /> */}
    </div>

    
  );
};