import React, { useState, useEffect } from 'react';

const OpportunitiesPage = () => {
  const [opportunities, setOpportunities] = useState([]);

  useEffect(() => {
    fetch('/data-source/opportunities.json')
      .then(response => response.json())
      .then(data => setOpportunities(data))
      .catch(error => console.error('Error fetching opportunities data:', error));
  }, []);

  return (
    <div className="opportunities-container">
      {opportunities.map(opportunity => (
        <a key={opportunity.id} href={opportunity.url} className="opportunity-link">
          <div className="opportunity-card">
            <div className="opportunity-image">
              <img src={opportunity.imageUrl} alt="Opportunity Image" />
            </div>
            <div className="opportunity-header">
              <div className="opportunity-info">
                <p>{opportunity.lineItemDescription}</p>
              </div>
              <div className="opportunity-description">
                <p>{opportunity.detailedDescription}</p>
              </div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default OpportunitiesPage;
