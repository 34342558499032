import React from 'react';
import { Header, Footer } from '../../../layout';
import { Link } from 'react-router-dom';
import './scss/members.scss'; // Ensure this is the correct path to your SCSS file
import DashboardIcon from '../../../assets/images/app-buttons/dashboard-button.png';
import TrainingIcon from '../../../assets/images/app-buttons/training-button.png';
import StoreIcon from '../../../assets/images/app-buttons/store-button.png';
import IncomeIcon from '../../../assets/images/app-buttons/income-button.png';
import BogleIcon from '../../../assets/images/app-buttons/media-button.png';

export const MembersLanding = ({ visible }) => {
  return (
    <div className="members_landing-page">
      <Header visible={visible} />
      
      <div className="levelMemb_section">
        <div className="nested_levelMemb">
          <div className="icon-buttons">
            <Link to="/dashboard" className="icon-placeholder">
              <img src={DashboardIcon} alt="Dashboard" />
            </Link>
            <Link to="/training" className="icon-placeholder">
              <img src={TrainingIcon} alt="Training" />
            </Link>
            <Link to="/store" className="icon-placeholder">
              <img src={StoreIcon} alt="Store" />
            </Link>
            <Link to="/opportunity" className="icon-placeholder">
              <img src={IncomeIcon} alt="Income" />
            </Link>
            <Link to="/bogle" className="icon-placeholder">
              <img src={BogleIcon} alt="Bogle" />
            </Link>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};
