import React from "react";
import { Header, Footer } from "../../../layout";
import LoomVideos from './LoomVideo.js'; // Adjust the path based on your project structure
import "./scss/index.scss";

export const Archives = ({ visible }) => {
  return (
    <div>
      <Header visible={visible} />
      <div className="archive_section">
        <LoomVideos />
      </div>
      <Footer />
    </div>
  );
};
