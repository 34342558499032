import { useState, useEffect } from 'react';
import { useChainId } from '@thirdweb-dev/react';

const useCryptoPrice = () => {
  const chainId = useChainId();
  const [cryptoPrice, setCryptoPrice] = useState('Loading...');
  const [error, setError] = useState(null);

  // Define fetch functions for each source
  const fetchFromCoinGecko = async (cryptoId) => {
    try {
      const response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${cryptoId}&vs_currencies=usd`);
      const data = await response.json();
      return data[cryptoId].usd;
    } catch (error) {
      console.error('CoinGecko:', error);
    }
    return null;
  };


  const fetchFromBitstamp = async (cryptoSymbol) => {
    try {
      const url = "https://www.bitstamp.net/api/v2/ticker/"+cryptoSymbol.toLowerCase()+"usd/";
      const response = await fetch(url);
      const data = await response.json();
      return parseFloat(data.last);
    } catch (error) {
      console.error('Binance:', error);
    }
    return null;
  };

  // Mapping of chain IDs to their respective cryptocurrency symbols/IDs
  const cryptoMapping = {
    1: { coingecko: 'ethereum', binance: 'ETH' }, // Ethereum
    56: { coingecko: 'binancecoin', binance: 'BNB' }, // BSC
    97: { coingecko: 'binancecoin', binance: 'BNB' }, // BSC Test
    137: { coingecko: 'matic-network', binance: 'MATIC' }, // Matic
    8453: { coingecko: 'ethereum', binance: 'ETH' }, // Base
    43114: { coingecko: 'avalanche', binance: 'AVAX' }, // AVAX
    // Add other mappings as needed
  };

  useEffect(() => {
    const fetchPrice = async () => {
      if (!chainId) {
        setError('Chain ID is undefined');
        return;
      }

      const crypto = cryptoMapping[chainId];
      if (!crypto) {
        setError(`Unsupported chain ID: ${chainId}`);
        return;
      }

      let price = await fetchFromCoinGecko(crypto.coingecko);
      if (price !== null) {
        setCryptoPrice(price);
        return;
      }

      price = await fetchFromBitstamp(crypto.binance);
      if (price !== null) {
        setCryptoPrice(price);
        return;
      }

      setError('Failed to fetch price from all sources');
    };

    fetchPrice();
  }, [chainId]);

  return { cryptoPrice, error };
};

export default useCryptoPrice;
