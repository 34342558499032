import React, { useRef, useState } from "react";
import "./scss/index.scss";

export const RegistrationForm = () => {
  const form = useRef();


  const [regobj, setRegObj] = useState({
    user_name: "",
    user_email: "",
    user_number: "",
    message: "",
  });


  const getDetails = (e) => {
    // e.preventDefault();
    setRegObj({ ...regobj, [e.target.name]: e.target.value });
  };

  const onSubmitReg=(e) =>{
    e.preventDefault()
    console.log(regobj);
    fetch("https://dcgelitenft.com/registration.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(regobj),
    })
      .then((response) => { 
      console.log(response);
      return response.json()
    })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }


  return (
    <div className="registration_form">
      <h1>Need more info?  Send a Message</h1>
      <div className="form_section">
        <div className="form_input py-5">
          <form ref={form} onSubmit={onSubmitReg} >
            <div>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Name"
                name={"user_name"}
                onChange={getDetails}
                required
              />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                placeholder="Email"
                name={"user_email"}
                onChange={getDetails}
                required
              />
            </div>
            <div>
              <label htmlFor="number">Phone</label>
              <input
                type="number"
                id="number"
                placeholder="Phone Number"
                name={"user_number"}
                onChange={getDetails}
                required
              />
            </div>
            <div>
              <label htmlFor="message">Message</label>
              {/* <input type="text" id="name" placeholder="Username" /> */}
              <textarea
                id="message"
                rows="3"
                placeholder="Message"
                name={"message"}
                onChange={getDetails}
                required
              ></textarea>
            </div>
            <div className="my-3">
            <button type="submit" name="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
