import React from "react";
import "./scss/index.scss";
import { Footer, Header } from "../../layout";
import logo from "./activity/images/future.png";
import Cards from "./activity/index";
import promoImage from "./activity/images/access.png"
import {RegistrationForm} from "../registrationform";

export const Promotions = ({ visible }) => {
  return (
    <div className="promoation_section w-full">
      <Header visible={visible} />
      <div className="nested_privacy">
        <div className="child_privacy">
          <div className="logo_privacy">
            <img src={logo} alt="logo" />
          </div>
          <div className="privacy_content">
            <div className="promo_text">
              <h2>United Success Center Discord Access</h2>
              <p>Hey crypto enthusiasts and trading warriors! 🚀 Ready to elevate your trading game? Join us at DCG, where we transform entrepreneurs into pro traders! By stepping into our DCG Discord, you'll secure your VIP seat in the United Success Center Discord, unlock access to cutting-edge trading bots, dive into deep, insightful trading chats, and crack the code with our exclusive Trading Secret Report. It's all about trading together and winning together. 🎯 Let's conquer the crypto world!</p>
            </div>
            <div className="promo_container">
            <div className="promo_image">
               <img src={promoImage} alt="DCG Promotions" />
            </div>
            <div className="promo_text">
             <h4>DCG NFT Holders have Access</h4>
              <p>As a DCG NFT holder, you're entitled to exclusive access to our Discord community, a privilege that requires verifying your wallet to confirm your NFT ownership. To begin this process, you should locate and click on the NFT verification button available on our website. This action triggers a series of user-friendly prompts designed to guide you through a secure verification process.</p>
              <h4>Follow the Steps</h4>
              <p>During these steps, you will be requested to connect your digital wallet. It's important to ensure that this is the wallet where your DCG NFT is stored. The system then automatically checks for the presence of a DCG NFT in your connected wallet, a critical step for gaining access to the Discord community. Once your NFT is successfully verified, you'll receive a confirmation, and you may need to follow a few final instructions to complete the process. After these steps, you'll be all set to join and engage with fellow NFT holders in our exclusive Discord community.</p>
              <h4>Learn From The Experts</h4>
              <p>The rise of DeFi and alternative banking is an exciting development for individuals who are looking for more control and transparency in their financial lives. As these technologies continue to evolve and mature, they have the potential to disrupt traditional banking systems and offer a new path forward for those seeking a more decentralized and democratic financial future.</p>
            
            <div className="buybutton">
              <a href="https://discord.gg/R9htGD2Akn"><button class="button">Verify Your Wallet</button></a>
              

              <div class="button_info">
                <p>Once you complete your verification you will be granted access based on your NFT membership level.</p>
              </div>
              </div>


   
            </div>
            </div>
            
            
          </div>
          
        </div>
       
        <RegistrationForm />
        
      </div>
      <Footer />
    </div>
  );
};
