import React from "react";
import { Header, Footer } from "../../../../layout";
import ProductCard from "./productCard"; // Make sure the path matches your file structure


import "./scss/store-index.scss";

export const Store = ({ visible }) => {

  
  return (
    <div>
      <Header visible={visible} />
       <ProductCard />
      <Footer />
      
    </div>
  );
};