import React, { useState } from "react";
import "./scss/index.scss";
import gifDSG from "../../../assets/themes/USC-1/dark/site-images/NFT-Logo.gif";
import Gallery from '../../../modules/mainpage/about/gallery.js'; // Adjust the path as needed
import { FaShoppingCart } from "react-icons/fa";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import abi from "../../../abi/abi.json";
import { useAddress } from "@thirdweb-dev/react";

export const About = () => {
  const { library } = useWeb3React();
  const account = useAddress();

  const [teamTwoID, setTeamTwoID] = useState("650");

  const contract_address = abi.contractAddress;

  async function BuyDCGNFTEth() {
    if (library !== "undefined") {
      try {
        const signer = library.getSigner(account);
        const contract = new ethers.Contract(contract_address, abi.abi, signer);
        const team2ID = await contract.team2();
        setTeamTwoID(String(team2ID));
        const sendTX = await contract.buyDCGNFT(teamTwoID, {

          value: "100000000000000000",
        });
        await sendTX.wait();
        const check = sendTX.toString();
        console.log(check);
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <div className="mainSection_about">
      <div className="nested_about">
        <div className="nestedSection">
          <div className="heading">
            
            <h1>USC Member NFT</h1>
            <h5 className="my-3">
              A Team with proven success in business.
            </h5>
          </div>
          <div className="description">
            <p>
            Welcome to United Success Center, we are a team with proven success in multiple business environments paving the way for your growth and achievement. We blend time-tested strategies with innovative approaches to empower both new and experienced entrepreneurs. Our platform offers comprehensive training, insightful resources, personalized mentorship, and opportunity, all designed to elevate your journey in this dynamic industry. Join us and be part of a community that's not just building businesses, but also shaping the future of network marketing.{" "}
            </p>
            
          </div>
          
          <Gallery />
          
          
          
        </div>
        <div className="nesteSection_two">
          
          {/* <div className="timer">
            <p>00d :</p>
            <p>00h :</p>
            <p>00m :</p>
            <p>00s </p>
          </div> */}
      
          <div className="content_Box">
            <div className="gif_logo">
            <img src={gifDSG} alt="gifDSG" />
          </div>
            <p className="my-2">
            Exclusively for NFT holders, our platform provides a unique blend of in-depth training, advanced resources, personalized mentorship, and opportunity. Joining us means unlocking access to a suite of tools and insights perfect for aspiring and experienced network marketers alike. Seize this chance to evolve, learn, and influence the network marketing landscape.
            </p>
            <div className="text-center my-2 price_eth">
            Price: 0.1 BNB
            </div>
            {teamTwoID >= 650 ? (
  <div className="flex justify-center items-center space-x-4"> {/* Added space-x-4 for some spacing */}
    <button onClick={BuyDCGNFTEth}>
      <FaShoppingCart className="inline -mt-2 mr-2" />
      <span>Mint Now</span>
    </button>
    <button onClick={BuyDCGNFTEth}> {/* Adjust this onClick to a different function if needed */}
      <fa-square-share-nodes className="inline -mt-2 mr-2" />
      <span>Referral Link</span>
    </button>
  </div>
) : null}

          </div>
        </div>
      </div>
    </div>
  );
};
