// useContractInitializer.js

import { useState, useEffect } from 'react';
import { ThirdwebSDK } from '@thirdweb-dev/sdk';
import { ethers } from 'ethers';
import useCryptoPrice from './useCryptoPrice';


// Import network logos and ABI
import ethLogo from '../assets/images/networks/eth.png';
import bnbLogo from '../assets/images/networks/bnb.png';
import polygonLogo from '../assets/images/networks/polygon.png';
import baseLogo from '../assets/images/networks/base.png';
import avaxLogo from '../assets/images/networks/avax.png';
import contractABI from '../abi/contractABI';

// Define contract addresses, network logos, and symbols
const contractAddresses = {
    1: '0x9B4ea303Ca6302DFA46b73bC660598c65DE96B3d', // Ethereum Mainnet
    56: '0xC33E0b83D395FBBa0e2E463b10029F8379AD9c7e', // Binance Smart Chain
    97: '0x21d5feB8eDEfcD98181361Cd00a5A34C50cF479c', // Binance Smart Chain Testnet
    137: '0xe7c15C988528baE6E2C6961E394f0ad663e52CB5', // Polygon/MATIC
    43114: '0x2234a5D9F716f5e39c84a5f8FF1D8DcA5A40328b', // Avalanche
    8453: '0xC8388A09B11325AF2F94bEefa4B7281359445789', // Base
};

const networkLogos = {
    1: ethLogo,
    56: bnbLogo,
    97: bnbLogo,
    137: polygonLogo,
    43114: avaxLogo,
    8453: baseLogo,
};

const networkSymbols = {
    1: "ETH",
    56: "BNB",
    97: "BNB",
    137: "MATIC",
    43114: "AVAX",
    8453: "ETH",
};


export const useGS50Contract = (chainId) => {
    const [contract, setContract] = useState(null);
    const [gs50PriceInUsd, setGs50PriceInUsd] = useState('Loading...');
    const [logo, setLogo] = useState(networkLogos[chainId] || '');
    const [symbol, setSymbol] = useState(networkSymbols[chainId] || '');
    const { cryptoPrice } = useCryptoPrice(chainId); // Fetch the current price of the native currency in USD

    useEffect(() => {

        setLogo(networkLogos[chainId] || '');
        setSymbol(networkSymbols[chainId] || '');
        const initializeContract = async () => {
            if (!chainId) {
            // console.log("Waiting for chainId...");
              return;
            }

            if (!contractAddresses[chainId]) {
                console.error('Unsupported or unknown chain ID:', chainId);
                return;
            }

            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();
                const contractAddress = contractAddresses[chainId];
                const contractInstance = new ethers.Contract(contractAddress, contractABI, signer);
                // console.log("Contract initialized with chainId:", chainId);
                setContract(contractInstance);

                // Assuming your contract has a method to get the GS50 price in the native currency
                const priceInNativeCurrency = await contractInstance.buyPrice();
                const priceInNativeCurrencyFormatted = ethers.utils.formatEther(priceInNativeCurrency);

                // Convert the GS50 price to USD
                if (cryptoPrice !== "Loading..." && cryptoPrice !== "Error") {
                    const priceInUsd = parseFloat(priceInNativeCurrencyFormatted) * parseFloat(cryptoPrice);
                    setGs50PriceInUsd(priceInUsd.toFixed(2));
                } else {
                    setGs50PriceInUsd('Price feed unavailable. Try again.');
                }
            } catch (error) {
                console.error('Error initializing the contract or fetching GS50 price:', error);
            }
        };

        initializeContract();
    }, [chainId, cryptoPrice]);

    const isContractReady = !!contract;
    return { contract, gs50PriceInUsd, logo, symbol, isContractReady };
};