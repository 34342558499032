// Main component (Bogle)
import React, { useState, useEffect } from 'react';
import { Header, Footer } from "../../../../layout";
import LeftMenuBar from './leftmenubar';
import NftList from './nftlist';
import MediaPlayer from './player';

// import BoglePlayer from './bogle';

import "./scss/index.scss";
import { useAddress, useChainId } from '@thirdweb-dev/react';

const Bogle = ({visible}) => {
  const [showNftList, setShowNftList] = useState(false);
  const [selectedNft, setSelectedNft] = useState(null);
  const [nfts, setNfts] = useState([]); // Populate this with actual NFT data

  const account = useAddress();
  const chainId = useChainId();

  const handleNftsSelected = () => {
    setShowNftList(true);
    // Implement fetching NFTs from the wallet here
  };

  const handleSelectNft = (nft) => {
    setSelectedNft(nft);
    setShowNftList(false);
  };

  useEffect(() => {
    if(!!chainId && !!account) {
      setTimeout(() => {
        const frame = document.getElementById('player-frame');
        frame.contentWindow.postMessage({chain: chainId, acc: account}, 'https://bogle-player-v1.vercel.app/');
      }, 3000)
      
    }
  }, [chainId, account])
  

  return (
    <div className="app">
      <Header visible={visible}/>
      {/* <LeftMenuBar onNftsSelected={handleNftsSelected} />
      <NftList onNftsSelected={handleNftsSelected} /> */}
      {selectedNft && <MediaPlayer src={selectedNft.mediaUrl} poster={selectedNft.image} />}
      <iframe id="player-frame" src="https://bogle-player-v1.vercel.app/" style={{width: '100%', height: '1000px', marginTop: 8}}></iframe>
      <Footer />
    </div>
  );
};

export default Bogle;
