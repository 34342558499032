import React from "react";
import { Header, Footer } from "../../index";
import { MainPage } from "../../../modules";

export const Home = ({ visible }) => {
  return (
    <div>
      <Header visible={visible} />
      <MainPage />
      <Footer />
    </div>
  );
};
