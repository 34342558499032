// player.js
import React from 'react';

const MediaPlayer = ({ src, poster }) => {
  return (
    <div className="media-player">
      {/* Use <video> or <img> depending on the type of media */}
      {src.endsWith('.mp3') ? (
        <img src={poster} alt="NFT" />
      ) : (
        <video src={src} controls poster={poster} />
      )}
    </div>
  );
};

export default MediaPlayer;
