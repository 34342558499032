// import { ethers } from "ethers";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Home } from "./layout";
import {
  PrivacyPage,
  TermsServices,
  RefundPolicy,
  Promotions,
  Archives,
} from "./modules";
//import Metaverse from "./modules/metaverse";
import DashboardOverlay from "./modules/mainpage/memberslanding/dashboard"; // Imported DashboardOverlay
import { TrainingModule } from "./modules/mainpage/memberslanding/training"; // Imported Training Mod
import { OpportunityModule } from "./modules/mainpage/memberslanding/opportunity/"; // Imported Training Mod
import { Store } from "./modules/mainpage/memberslanding/store"; // Imported Training Mod
import Bogle from "./modules/mainpage/memberslanding/bogle";
 
//import { Promotions } from "./modules/promotions";
import Protected from "./layout/header/Protected";
import abi from "./abi/abi.json";
import { useState, useEffect } from "react"; // Combined useState and useEffect import
import { ThirdwebProvider } from "@thirdweb-dev/react";

function App() {
  const [visible, setVisible] = useState(false); // Changed 'var' to 'const' for state declaration

  const contract_address = abi.contractAddress;
  
  // useEffect(() => {
  //   (async () => {
  //     if (!library || !account) return;
  //     await window.ethereum.request({ method: "eth_requestAccounts" });
  //     const contract = new ethers.Contract(
  //       contract_address,
  //       abi.abi,
  //       library.getSigner()
  //     );
  //     const balance = await contract.balanceOf(account);
  //     console.log("🚀 ~ NFT", Number(String(balance)));

  //     if (Number(String(balance)) > 0) {
  //       setVisible(true);
  //     }
  //   })();
  // }, [account, library]); // Added 'library' to useEffect dependencies

  return (
    <ThirdwebProvider activeChain={'goerli'} clientId={process.env.CLIENT_ID}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home visible={visible} />} />
          <Route path="/privacy-policy" element={<PrivacyPage visible={visible} />} />
          <Route path="/terms-service" element={<TermsServices visible={visible} />} />
          <Route path="/refund-policy" element={<RefundPolicy visible={visible} />} />
          <Route path="/dcg-promotions" element={<Promotions visible={visible} />} />
          {/* <Route path="/media" element={<Protected visible={visible}><Media visible={visible} /></Protected>} /> */}
          <Route path="/archive" element={<Protected visible={visible}><Archives visible={visible} /></Protected>} />
          
          {/* New Dashboard Route */}
          <Route path="/dashboard" element={<DashboardOverlay visible={visible} />} />
          {/* New Training Route */}
          <Route path="/training" element={<TrainingModule visible={visible} />} />
          <Route path="/opportunity" element={<OpportunityModule visible={visible} />} />
          <Route path="/store" element={<Store visible={visible} />} />
          <Route path="/bogle" element={<Bogle visible={visible} />} />
        </Routes>
      </div>
    </ThirdwebProvider>
  );
}

export default App;