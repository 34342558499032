import React from 'react';

const LoomVideos = () => {
  const videoUrls = [
    "https://www.loom.com/embed/097a97c85e3e4c7d82a3dd177a92fa42?sid=bf5b1223-f7b6-41b9-aff5-2aeb44795cc1",
    "https://www.loom.com/embed/da0b172e31f7429fa906c58f265a66e7?sid=45b37705-bd9a-4714-ab24-0aadb4408798",
    "https://www.loom.com/embed/71b994d899fd4cf0a18ded5732b6cfb1?sid=ba8cf3fe-0eda-4121-a011-cdff9bd5e042","https://www.loom.com/embed/e79716e6099a45eaa3ab4180e65a0c03?sid=5b678b49-809d-4637-a2d5-02b3f8a7360d","https://www.loom.com/embed/0925606f7f854d2ab893f60cbba7560a?sid=f0ae3176-7e65-4c27-8a09-2e49c5407486","https://www.loom.com/embed/3d3407a297fb4f50bde7de5246f1b2b0?sid=9d2da76b-6d2a-4b9c-875f-886072c6510c",
    // ... other video URLs
  ];

  return (
    <div style={{ padding: '50px', paddingTop: '100px' }}>
      <h1 className="page-title">Training Archives</h1>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '20px' }}>
        {videoUrls.map((url, index) => (
          <div key={index} style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
            <iframe
              src={url}
              frameBorder="0"
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoomVideos;
