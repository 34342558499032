import "../../mainpage/about/scss/index.scss";
import mintNFT from '../../../assets/themes/USC-1/dark/site-images/mintNFT.png';
import connectNetwork from '../../../assets/themes/USC-1/dark/site-images/connectNetwork.png';
import digitalPartner from '../../../assets/themes/USC-1/dark/site-images/digitalPartner.png';

import "./scss/index.scss";



const Gallery = () => {
    return (
      <div className="image-gallery">
        <div className="gallery-item">
          <img src={mintNFT} alt="Mint NFT" className="gallery-image" />
          <div className="image-title">Mint Your NFT</div>
        </div>
        <div className="gallery-item">
          <img src={connectNetwork} alt="Connect Network" className="gallery-image" />
          <div className="image-title">Connect With Our Network</div>
        </div>
        <div className="gallery-item">
          <img src={digitalPartner} alt="Digital Partner" className="gallery-image" />
          <div className="image-title">Become A Digital Partner</div>
        </div>
      </div>
    );
  };
  
  export default Gallery;