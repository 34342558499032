import React from "react";
import "./scss/index.scss";
import { ImCross } from "react-icons/im";
import { NavLink } from "react-router-dom";
// import logo from "../../assets/images/sports.png";

export const Sidebar = ({ ToggleSidebar, isOpen, visible }) => {
  return (
    <div>
      <div className={`sidebar ${isOpen == true ? "active" : ""}`}>
        <div className="sd-header">
          {/* <h4 className="mb-0">Sidebar Header</h4> */}
          {/* <img src={logo} alt="logo" className="w-28" /> */}
          <div className="btn-primary p-4 text-base rounded-md cursor-pointer" onClick={ToggleSidebar}>
            {/* <i className="fa fa-times"></i> */}
            <ImCross />
          </div>
        </div>
        <div className="sd-body">
          <ul>
            <li>
              {/* <a className="sd-link">Menu Item 1</a> */}
              <NavLink
              className={"px-3 sd-link"}
              to={"https://www.facebook.com/"}
              target="_blank"
            >
              Facebook
            </NavLink>
            </li>
            <li>
              {/* <a className="sd-link">Menu Item 2</a> */}
              <NavLink
              className={"px-3 sd-link"}
              to={"https://www.instagram.com/"}
              target="_blank"
            >
              Instagram
            </NavLink>
            </li>
            <li>
              {/* <a className="sd-link">Menu Item 4</a> */}
              {/* <NavLink className={"px-3 sd-link"} to={"/dcg-promotions"}>
              Promotions
            </NavLink> */}
            </li>
            {visible ? (
              // <div className="dropdown">
                // <p>Members Portal</p>
                // <div className="dropdown-content">
                  <>
                  <li>
                    <NavLink to="/media">Members Portal</NavLink>
                    </li>
                  </>
                // </div>
              // </div>
            ) : (
              null
            )}
{/* 
            <li>
              <a className="sd-link">Menu Item 5</a>
            </li>
            <li>
              <a className="sd-link">Menu Item 6</a>
            </li>
            <li>
              <a className="sd-link">Menu Item 7</a>
            </li>
            <li>
              <a className="sd-link">Menu Item 8</a>
            </li> */}
          </ul>
        </div>
      </div>
      <div
        className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
    </div>
  );
};
